<template>
	<ResetPasswordComponent />
</template>

<script>
import ResetPasswordComponent from '@/components/ResetPassword.vue';

export default {
	name: 'ResetPassword',
	components: {
		ResetPasswordComponent,
	},
};
</script>
