<template>
	<v-row class="mb-6" justify="center" no-gutters>
		<div class="d-flex flex-column message-error-container">
			<h1>
				Oups une erreur
				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<span class="activeRules" v-on="on">s'est</span>
					</template>
					<span>Dans cette phrase on écrit "s'est" puisqu'il est toujours suivi d'un participe passé</span>
				</v-tooltip>
				produite
			</h1>
			<p class="more-error">Veuillez nous excuser, nous travaillons activement pour résoudre ce problème</p>
			<div class="image-error-container">
				<img src="@/assets/images/error.jpg" class="image-error" alt="" />
			</div>
		</div>
	</v-row>
</template>

<script>
export default {
	name: 'Error',
};
</script>

<style scoped>
.activeRules {
	text-decoration: initial;
	background-image: linear-gradient(#6afdef);
	border-radius: 5px;
}
.activeRules:hover {
	text-decoration: underline;
}
.image-error {
	max-width: 50%;
}
.image-error-container {
	text-align: center;
	margin-top: 50px;
}
.message-error-container {
	margin-top: 50px;
	text-align: center;
	padding-left: 30px;
	padding-right: 30px;
}
.more-error {
	margin-top: 20px;
}
@media only screen and (max-width: 600px) {
	.image-error {
		max-width: 80%;
	}
	.more-error {
		padding-left: 30px;
		padding-right: 30px;
	}
}
</style>
